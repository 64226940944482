/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

body {
  margin: 0;
  min-height: 100vh;
}

* {
  box-sizing: border-box;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

button {
  margin: 10px 0; /* Add some spacing between buttons */
  width: 150px;
  border-radius: 20px !important;
}

.mat-orange {
  background-color: #FF7F66 !important;
  color: #fff !important;
}

.rectangle {
  background-color: #d3d3d3;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  width: 350px; /* Full width minus the margins */
  box-sizing: border-box;
  border: 2px solid black;
}

.loading-spinner-container {
  display: flex; /* New wrapper around the spinner */
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  height: 100vh; /* Make it take the full viewport height */
}
